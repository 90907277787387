<template>
  <a-modal
    :title="modal.title"
    v-model="modal.visible"
    :width="1000"
    @ok="handleOk"
    @cancel="handleCancel"
    destroyOnClose
  >
    <UmsUser ref="umsUser"></UmsUser>
  </a-modal>
</template>

<script>
import UmsUser from './UmsUser'
export default {
  components: {
    UmsUser,
  },
  data() {
    return {
      modal: {
        visible: false,
        title: '新增组成员',
        data: {},
      },
    }
  },
  methods: {
    start() {
      this.modal.visible = true
      return new Promise((resolve, reject) => {
        this.$once('end', function(state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    async handleOk() {
      const row = this.$refs.umsUser.selectedRows[0]
      this.$emit('end', 'ok', row)
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
  mounted() {},
}
</script>

<style></style>
