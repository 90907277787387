<template>
  <div class="page-areaManage">
    <a-card :bordered="false">
      <div class="queryView">
        <!-- <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="区域编码">
              <a-input class="form-control" v-decorator="['areaCode']" placeholder="请输入区域编码"></a-input>
            </a-form-item>
            <a-form-item label="区域名称">
              <a-input class="form-control" v-decorator="['areaName']" placeholder="请输入区域名称"></a-input>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div> -->
        <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table  multiRow-table"
            :rowKey="tableRowKey"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
            :expandedRowRender="tableExpandedRowRender"
            @expand="tableExpand"
            :expandedRowKeys.sync="expandedRowKeys"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="区域名称">
          <a-input
            v-decorator="[
              'areaName',
              {
                rules: [{ required: true, message: '请输入区域名称' }],
              },
            ]"
            placeholder="请输入区域名称"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
    <SelectUmsUser ref="selectUmsUser"></SelectUmsUser>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import SelectUmsUser from './modules/SelectUmsUser'
import { groupList } from './services/commonTypes'
export default {
  components: {
    SelectUmsUser,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '区域编码',
        width: '43%',
        align: 'center',
        dataIndex: 'areaCode',
      },
      {
        title: '区域名称',
        width: '43%',
        align: 'center',
        dataIndex: 'areaName',
      },
      {
        title: '操作',
        align: 'center',
        width: '14%',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.edit(row)
                  }}
                >
                  编辑
                </a>

                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.remove(row)
                  }}
                >
                  删除
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableRowKey: 'id',
      tableColumns: tableColumns,
      expandedRowKeys: [],
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
    }
  },
  computed: {
    typesMap() {
      return {
        groupList: groupList,
      }
    },
  },
  methods: {
    async tableExpand(expanded, record) {
      const { tableRowKey, dataSource } = this
      const currentGroupCode = ['k03', 'k04']
      if (expanded) {
        const res = await this.$axios.post('/fast-repair/admin/projectUserPermission/queryByCode', {
          commonCode: record.areaCode,
          groupCode: currentGroupCode.join(','),
        })
        const userList = res.data
        const userGroup = {}
        currentGroupCode.map(code => {
          userGroup[code] = []
        })
        userList.map(user => {
          const group = user.groupCode
          if (!userGroup[group]) {
            userGroup[group] = [user]
          } else {
            userGroup[group].push(user)
          }
        })
        const rowIndex = dataSource.findIndex(row => row[tableRowKey] === record[tableRowKey])
        this.$set(this.dataSource[rowIndex], 'userGroup', userGroup)
      }
      // console.log(expanded, record)
    },
    async addGroupUser(tableIndex, groupCode, commonCode, permissionLevel = 1) {
      try {
        const row = await this.$refs.selectUmsUser.start()
        await this.$axios.post('/fast-repair/admin/projectUserPermission/add', {
          commonCode,
          groupCode,
          permissionLevel,
          umsAccountId: row.accountId,
          umsAccountName: row.userName,
        })
        this.$message.success('操作成功!')
      } catch (e) {}
      // 打开了用户界面，可能会有改名称的操作，所以无论成功失败，都刷新
      this.tableExpand(true, this.dataSource[tableIndex])
    },
    async deleteGroupUser(tableIndex, id) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '确认删除?',
          content: '用户将从当前组移除',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/fast-repair/admin/projectUserPermission/delete', { id })
      this.$message.success('操作成功!')
      this.tableExpand(true, this.dataSource[tableIndex])
    },
    tableExpandedRowRender(record, tableIndex) {
      const userGroupList = []
      if (record.userGroup) {
        for (const groupCode in record.userGroup) {
          const groupName = this.translateType(groupCode, 'groupList')
          userGroupList.push({
            groupCode: groupCode,
            groupName: groupName,
            list: record.userGroup[groupCode],
          })
        }
      }
      return (
        <div class="usergroup-list">
          {userGroupList.map(groupItem => {
            return (
              <div class="usergroup-item">
                <div class="usergroup-item-hd">
                  <span>{groupItem.groupName}</span>
                  <a-icon
                    type="plus-circle"
                    class="action-icon"
                    onClick={() => {
                      this.addGroupUser(tableIndex, groupItem.groupCode, record.areaCode)
                    }}
                  />
                </div>
                <div class="usergroup-user-list">
                  {groupItem.list.map((item, index) => {
                    return (
                      <div key={index} class="usergroup-user-item">
                        <a-icon type="user" />
                        <span>{item.umsAccountName}</span>
                        <a-icon
                          type="minus-circle"
                          class="action-icon"
                          onClick={() => {
                            this.deleteGroupUser(tableIndex, item.id)
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      )
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/fast-repair/admin/area/page', {
          ...this.getPageParams(_pageNo, _pageSize),
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
        this.expandedRowKeys = []
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    add() {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增楼栋'
    },
    async edit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '编辑楼栋'
      this.modal.data = { ...row }
      this.safeSetFieldsValue(this.modal.form, { ...row })
    },
    async remove(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确认删除?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/fast-repair/admin/area/delete', {
        id: row.id,
      })
      this.$message.success('操作成功!')
      this.doQuery()
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const { id } = this.modal.data
            const actionUrl = id ? '/fast-repair/admin/area/update' : '/fast-repair/admin/area/add'
            await this.$axios.post(actionUrl, {
              id,
              ...params,
            })
            this.$message.success('操作成功!')
            this.modal.visible = false
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
  },
  async mounted() {
    // await this.$store.dispatch('GetCollection', 'KX.KX_ROLE_LIST')
    this.doQuery()
  },
}
</script>

<style lang="less" scoped>
.usergroup-item {
  position: relative;
  .usergroup-item-hd {
    height: 40px;
    display: flex;
    align-items: center;
  }
  &:before {
    content: '';
    position: absolute;
    left: 10px;
    width: 0;
    top: 40px;
    bottom: 20px;
    border-left: 1px dashed #999;
  }
  .action-icon {
    color: @primary-color;
    margin-left: 15px;
    cursor: pointer;
    font-size: 20px;
  }
  .usergroup-user-list {
    color: #999;
    padding-top: 20px;
    .usergroup-user-item {
      position: relative;
      height: 40px;
      padding-left: 40px;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        position: absolute;
        left: 10px;
        width: 30px;
        top: 20px;
        height: 0;
        border-top: 1px dashed #999;
      }
    }
  }
}
</style>
