<template>
  <div class="page-umsUser">
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="手机号码">
              <a-input v-decorator="['phone']" placeholder="请输入手机号码"></a-input>
            </a-form-item>
            <a-form-item label="用户名">
              <a-input v-decorator="['userName']" placeholder="请输入用户名"></a-input>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table tight"
            :rowKey="tableRowKey"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            :rowSelection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onRowSelectChange,
              type: 'radio',
              columnWidth: '2%',
            }"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="手机号码">
          <a-input
            :disabled="!!modal.data[tableRowKey]"
            v-decorator="[
              'phone',
              {
                rules: [{ required: true, message: '请输入手机号码' }],
              },
            ]"
            placeholder="请输入手机号码"
          ></a-input>
        </a-form-item>
        <a-form-item label="用户名">
          <a-input
            v-decorator="[
              'userName',
              {
                rules: [{ required: true, message: '请输入用户名' }],
              },
            ]"
            placeholder="请输入用户名"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '手机号码',
        width: '20%',
        align: 'center',
        dataIndex: 'phone',
      },
      {
        title: '用户名',
        width: '20%',
        align: 'center',
        dataIndex: 'userName',
      },
      {
        title: '操作',
        width: '20%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.edit(row)
                  }}
                >
                  修改
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      tableRowKey: 'accountId',
      dataSource: [],

      selectedRowKeys: [],
      selectedRows: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
    }
  },
  computed: {
    typesMap() {
      return {
        state: [
          { value: 1, label: '启用' },
          { value: 0, label: '停用' },
        ],
      }
    },
  },

  methods: {
    onRowSelectChange(keys, rows) {
      this.selectedRowKeys = keys
      this.selectedRows = rows
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/ums/Kx/listPage', {
          ...this.getPageParams(_pageNo, _pageSize),
        })
        const list = res.data.data
        if (list.length) {
          const defaultRow = list[0]
          this.onRowSelectChange([defaultRow[this.tableRowKey]], [defaultRow])
        }
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {}
      this.loading = false
    },
    add() {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增'
    },
    async edit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '修改'
      this.modal.data = row
      this.safeSetFieldsValue(this.modal.form, row)
    },
    async remove(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确定删除记录?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      this.$message.success('操作成功!')
      this.doQuery()
    },

    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const actionUrl = this.modal.data[this.tableRowKey] ? '/ums/Kx/updateJson' : '/ums/Kx/saveJson'
            await this.$axios.post(
              actionUrl,
              {
                ...params,
                [this.tableRowKey]: this.modal.data[this.tableRowKey],
              },
              {
                postDataType: 'json',
              }
            )
            this.$message.success('操作成功!')
            this.resetModal()
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
  },
  mounted() {
    // this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
